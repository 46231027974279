import { Helmet } from "react-helmet-async"

import PricingSection from "../components/Pricing/PricingSection"
import HraContact from "../components/Hra/HraContact"

export default function Pricing() {
	return (
		<>
			<Helmet>
				<title>Salusion - Pricing</title>
			</Helmet>
			<PricingSection />
			<HraContact />
		</>
	)
}
