import { Helmet } from "react-helmet-async"

import ProductsHeroClick from "../components/Products/ProductsHeroClick"
import "../components/Products/Products.css"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import ichravsqshera from "../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../assets/qsehra-rules.svg"
import video1 from "../videos/products.mp4"
import guideQsehra from "../assets/guide-salusions.svg"

import salusionVideo from "../videos/products.mp4"

import { PRICING_DATA } from "../components/Products/data"
import { TABLE_DATA } from "../components/Products/data"

export default function Qsehra() {
	return (
		<>
			<Helmet>
				<title>Salusion - Products</title>
			</Helmet>
			<ProductsHeroClick
				title="Salusion Simplifies QSEHRAs"
				poster={guideQsehra}
				video="https://www.youtube.com/embed/i2ERXJJkxkw?si=zO81hNyHZGOkrluN"
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
				
				<ResourceCenterVideo
					resourceImg={qsehraRules}
					title="Take a deep dive into the rules and regulations that govern QSEHRAs"
					pageLink="/product/qsehra/rules"
				/>
			</ResourceCenter>
			<PriceComparison title="QSEHRA Price Comparison" dataPricing={PRICING_DATA} dataCheckmark={TABLE_DATA} />
			<div className="products-hra-wrapper">
				<HraContact />
			</div>
		</>
	)
}
