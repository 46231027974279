import { Link } from "react-router-dom"
import "../styles.css"

export const question = "What is the QSEHRA uniformity requirement?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							With the exception of carryovers of unused benefit amounts, the employer cannot vary the amount made
							available for reimbursements among eligible employees. This is called the “uniformity requirement”.
						</p>

						<p className="text">
							When applying the uniformity rule, however, a QSEHRA may exclude the following employees (as further
							defined in IRS regulations applicable to self-funded health plans under Code §105):
						</p>

						<ul>
							<li>Employees who have not completed 90 days of service;</li>
							<li>Employees who have not attained age 25 before the beginning of the plan year;</li>
							<li>Part-time or seasonal employees;</li>

							<li>
								Non-participating employees covered by a collective bargaining agreement (if health benefits were the
								subject of good faith bargaining); and
							</li>

							<li>
								Nonresident aliens who do not receive earned income from the employer from sources within the United
								States.
							</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
