import { Link } from "react-router-dom"
import "../styles.css"

export const question = "What is the definition of a seasonal employee for an ICHRA and a QSEHRA?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Seasonal employees are defined under the Code §4980H or the Code §105 regulations, as elected by the HRA
							sponsor.
						</p>

						<ul>
							<li>
								Using Code §4980H, a seasonal employee performs labor or services on a seasonal basis as defined by the
								Secretary of Labor, including workers covered by section 500.20(s)(1) of title 29, Code of Federal
								Regulations and retail workers employed exclusively during holiday seasons
							</li>

							<li>
								Using Code §105, employees whose customary annual employment is less than 9 months, if other employees
								in similar work with the same employer (or, if no employees of the employer are in similar work, in
								similar work in the same industry and location) have substantially more months.
							</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
