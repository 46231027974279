import { Link } from "react-router-dom"
import "../styles.css"

export const question = "What are the reporting requirements of a QSEHRA?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							<b>Form W-2 Reporting</b>
							<br />
							Employers must report the total amount of the employee’s permitted QSEHRA benefit on Form W-2 in Box 12,
							using Code “FF.” IRS guidance clarifies that the amount to be reported for Form W-2 reporting purposes is
							the payments and reimbursement available to the employee for the calendar year, and not the payments and
							reimbursements actually received. The permitted benefit reported on the Form W-2 includes only newly
							available amounts, so if the QSEHRA allows for carryovers of unused amounts from prior years, any
							carryovers would not be included on an employee’s Form W-2 for a subsequent year.
						</p>

						<p className="text">
							<b>Patient-Centered Outcomes Research Trust Fund (PCOR) Fee</b>
							<br />
							Eligible employers that sponsor QSEHRAs must file Form 720 (Quarterly Federal Excise Tax Return) annually
							and pay the Patient-Centered Outcomes Research Trust Fund fee under Code §4376 for plan years ending
							before October 1, 2029. The Form 720 is due on July 31 of the year following the last day of the plan
							year.
						</p>

						<p className="text">
							<b>Information Reporting on IRS Form 1095-B</b>
							<br />
							IRS guidance clarifies that employers that provide a QSEHRA are not required to provide IRS Forms 1095-B
							(Health Coverage) with regard to the QSEHRA.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
