import "../components/Rules/Rules.css"

import IchraAccordion from "../components/Rules/IchraAccordion"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import file from "../assets/documents/ICHRA Rules & Regs.pdf"
import ichravsqshera from "../assets/ichravsqsehra-poster.svg"
import employees from "../assets/50-employees.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"
import guideIchra from "../assets/guide-to-ichra.svg"

export default function QsehraRules() {
	return (
		<>
			<section id="ichra_rules" className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>ICHRA Rules & Regs</h1>

					<div className="white-wrapper-all gsehra-rules-reg-wrapper">
						<h3>
							An Individual Coverage Health Reimbursement Arrangement (ICHRA) allows employers to reimburse employees
							for medical expenses up to a set annual allowance. These reimbursements are typically tax-free for both
							federal income and employment taxes.
						</h3>

						<a href={file} className="btn-linear" download="ICHRA Rules & Regs.pdf">
							Download PDF
						</a>

						<div className="guide-accordion-all">
							<IchraAccordion />
						</div>
					</div>
				</div>
			</section>

			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra"
				/>
				
				<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={employees}
					title="ICHRA topics for large employers"
					pageLink="/product/ichra/large-business"
				/>

				<ResourceCenterVideo
					resourceImg={ichraCalculator}
					title="Discover how much you can save with an ACA-compliant ICHRA"
					externalLink="product/aca/calculator"
				/>
			</ResourceCenter>
		</>
	)
}
