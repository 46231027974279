import { Link } from "react-router-dom"
import "../styles.css"

export const question = "Who is eligible to participate in a QSEHRA?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">Participation in a QSEHRA is extended to common-law employees.</p>

						<p className="text">
							The U.S. Supreme Court has held that when a statute uses a term that has an established common-law
							meaning, such as “employee,” the courts will infer that Congress intended to incorporate that meaning
							unless the statute provides otherwise. Because the statute does not offer any alternative definition of
							“employee,” the common-law definition seems likely to control for QSEHRA purposes.
						</p>

						<p className="text">Who is not a common-law employee:</p>

						<ul>
							<li>Self-employed individuals</li>
							<li>Partners in a partnership (considered self-employed)</li>
							<li>Members in a LLC (considered an owner)</li>
						</ul>

						<p className="text">
							IRS guidance clarifies that QSEHRAs cannot be offered to retirees, former employees, or non-employee
							owners (except, perhaps, as a covered family member), and further provides that a more-than-2% Subchapter
							S corporation shareholder who is otherwise an employee is not an employee for QSEHRA purposes.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
