import React from "react"
import { Table } from "react-bootstrap"

export default function DataTable({ dataTablePricing }) {
	return (
		<Table responsive className="table-all">
			<thead>
				<tr>
					<th></th>
					{dataTablePricing.map((curr, index) => (
						<th key={index}>{curr.heading}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{dataTablePricing.map((row, rowIndex) => (
					<tr key={rowIndex}>
						{row.cell.map((cellData, cellIndex) => (
							<td key={cellIndex}>
								<span>{cellData}</span>
							</td>
						))}
					</tr>
				))}
			</tbody>
		</Table>
	)
}
