import { Link } from "react-router-dom"
import { toSalusionPrivate } from "../../util"

import logo from "../../assets/logo.svg"
import "./Footer.css"

export default function Footer() {
	return (
		<footer id="footer">
			<div className="footer-links-wrapper">
				<div className="container">
					<div className="row justify-content-center justify-content-md-end justify-content-lg-center">
						<div className="col-12 col-md-4 col-lg">
							<div className="footer-logo">
								<Link to="/">
									<img src={logo} alt="salusion logo" />
								</Link>
							</div>
						</div>

						<div className="col-6 col-md-4 col-lg">
							<div className="footer-links">
								<h5>Legal</h5>
								<Link to="privacy-policy">Privacy Policy</Link>
								<Link to="terms-of-service">Terms of Service</Link>
							</div>
						</div>

						<div className="col-6 col-md-4 col-lg">
							<div className="footer-links">
								<h5>Sitemap</h5>
								<Link to="/">Home</Link>
								<Link to="pricing">Pricing</Link>
								<Link to="contact-us">Contact Us</Link>
							</div>
						</div>

						<div className="col-6 col-md-4 col-lg">
							<div className="footer-links">
								<h5>HRAs</h5>
								<Link to="product/qsehra">Qualified Small Employer</Link>
								<Link to="product/ichra">Individual Coverage</Link>
								<Link to="product/gchra">Group Coverage</Link>
								<Link to="product/ebhra">Excepted Benefit</Link>
							</div>
						</div>

						<div className="col-6 col-md-4 col-lg">
							<div className="footer-links">
								<h5>Resources</h5>
								<Link to="learning-center">Learning Center</Link>

								<Link onClick={(event) => toSalusionPrivate(event, "/product/aca/calculator")}>
									ACA-Compliant ICHRA
								</Link>

								<Link onClick={(event) => toSalusionPrivate(event, "/product/ichra/calculator")}>
									ICHRA Affordability Calculator
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="footer-copyright">
				<p>2024 Salusion, Inc. All Rights Reserved</p>
			</div>
		</footer>
	)
}
