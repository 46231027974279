import React from "react"
import ProductsHero from "../components/Products/ProductsHero"

import HraContact from "../components/Hra/HraContact"

import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import guideQsehra from "../assets/guide-salusions.svg"
import guideIchra from "../assets/guide-to-ichra.svg"
import qsehraVsIchra from "../assets/ichravsqsehra-poster.svg"

export default function QsehraVsIchra() {
	return (
		<>
			<ProductsHero
				title="ICHRA vs QSEHRA"
				video="https://www.youtube.com/embed/2f6mAMDRnSc?si=zO81hNyHZGOkrluN"
				buttonsHide={true}
				poster={qsehraVsIchra}
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra"
				/>
				
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="A health benefit for all employees offered by small companies"
					pageLink="/product/qsehra"
				/>
			</ResourceCenter>
			<HraContact />
		</>
	)
}
