import PriceComparisonTable from "./PriceComparisonTable"
import PricingTable from "./PricingTable"

export default function PriceComparison({ dataPricing, dataCheckmark, title }) {
	return (
		<section id="price_comparison">
			<div className="container">
				<div className="price-comparison-wrapper">
					<h2>{title}</h2>
					<div className="table-wrapper">
						<div className="pricing-table">
							<PricingTable dataTablePricing={dataPricing} />
						</div>
						<div className="comparison-table ">
							<PriceComparisonTable dataTableCheckmark={dataCheckmark} />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
