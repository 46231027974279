import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import "./index.css"

import RootLayout from "./pages/Root"
import Homepage from "./pages/Homepage"
import Pricing from "./pages/Pricing"
import ContactUs from "./pages/ContactUs"
import Qsehra from "./pages/Qsehra"
import Gchra from "./pages/Gchra"
import Ebhra from "./pages/Ebhra"
import CreateAccount from "./pages/CreateAccount"
import QsehraVsIchra from "./pages/QsehraVsIchra"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import TermsOfService from "./pages/TermsOfService"
import QsehraRules from "./pages/QsehraRules"
import IchraRules from "./pages/IchraRules"
import Ichra from "./pages/Ichra"
import LargeBusiness from "./pages/LargeBusinnes"
import ReimbursementOptions from "./pages/ReimbursementOptions"
import EmployerCalculator from "./pages/EmployerCalculator"
import FindBroker from "./pages/FindBroker"
import LearningCenter from "./pages/LearningCenter"
import LearningEntries from "./pages/LearningEntries"

const router = createBrowserRouter([
	{
		path: "/",
		element: <RootLayout />,
		children: [
			...LearningEntries,

			{
				index: true,
				element: <Homepage />
			},
			{
				path: "contact-us",
				element: <ContactUs />
			},
			{
				path: "pricing",
				element: <Pricing />
			},
			{
				path: "create-account",
				element: <CreateAccount />
			},
			{
				path: "privacy-policy",
				element: <PrivacyPolicy />
			},
			{
				path: "terms-of-service",
				element: <TermsOfService />
			},
			{
				path: "product/qsehra",
				element: <Qsehra />
			},
			{
				path: "product/ichra",
				element: <Ichra />
			},
			{
				path: "product/gchra",
				element: <Gchra />
			},
			{
				path: "product/ebhra",
				element: <Ebhra />
			},
			{
				path: "product/qsehra/rules",
				element: <QsehraRules />
			},
			{
				path: "product/ichra/rules",
				element: <IchraRules />
			},
			{
				path: "product/ichra-vs-qsehra",
				element: <QsehraVsIchra />
			},
			{
				path: "product/ichra/large-business",
				element: <LargeBusiness />
			},
			{
				path: "product/ichra/reimbursement-options",
				element: <ReimbursementOptions />
			},
			{
				path: "product/ichra/employer-calculator",
				element: <EmployerCalculator />
			},
			{
				path: "product/ichra/find-brokers",
				element: <FindBroker />
			},
			{
				path: "learning-center",
				element: <LearningCenter />
			}
		]
	}
])

function App() {
	return (
		<HelmetProvider>
			<RouterProvider router={router} />
		</HelmetProvider>
	)
}

export default App
