import checkmark from "../../assets/checkmark.svg"

export const TABLE_DATA = [
	{
		id: 1,
		cell1: "Plan Documents",
		cell2: checkmark,
		cell3: checkmark,
		cell4: checkmark
	},
	{
		id: 2,
		cell1: "Employee Communication",
		cell2: checkmark,
		cell3: checkmark,
		cell4: checkmark
	},
	{
		id: 3,
		cell1: "Insurance Verification",
		cell2: checkmark,
		cell3: checkmark,
		cell4: checkmark
	},
	{
		id: 4,
		cell1: "Expense Verification",
		cell2: checkmark,
		cell3: checkmark,
		cell4: checkmark
	},
	{
		id: 5,
		cell1: "ACH Reimbursements",
		cell2: checkmark,
		cell3: "",
		cell4: ""
	}
]

export const PRICING_DATA = [
	{
		id: 1,
		heading: "Salusion",
		cell: ["Per Participant Per Month", "$14", "$20", "$18"]
	},
	{
		id: 2,
		heading: "Take Command",
		cell: ["Platform Fee", "$0", "$40", "$35"]
	},
	{
		id: 3,
		heading: "PeopleKeep",
		cell: ["Setup Fee", "$0", "$0", "$150"]
	}
]

export const TABLE_DATA_ICHRA = [
	{
		id: 1,
		cell1: "Plan Documents",
		cell2: checkmark,
		cell3: checkmark,
		cell4: checkmark
	},
	{
		id: 2,
		cell1: "Employee Communication",
		cell2: checkmark,
		cell3: checkmark,
		cell4: checkmark
	},
	{
		id: 3,
		cell1: "Insurance Verification",
		cell2: checkmark,
		cell3: checkmark,
		cell4: checkmark
	},
	{
		id: 4,
		cell1: "Expense Verification",
		cell2: checkmark,
		cell3: checkmark,
		cell4: checkmark
	},
	{
		id: 5,
		cell1: "Reimbursements",
		cell2: checkmark,
		cell3: "",
		cell4: ""
	}
]

export const PRICING_DATA_ICHRA = [
	{
		id: 1,
		heading: "Salusion",
		cell: ["Per Participant Per Month", "$14", "$20", "$18"]
	},
	{
		id: 2,
		heading: "Take Command",
		cell: ["Platform Fee", "$0", "$40", "$35"]
	},
	{
		id: 3,
		heading: "PeopleKeep",
		cell: ["Onboarding Fee", "$0", "$0", "$150"]
	}
]
