import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import TestimonialCard from "./TestimonialCard"

import "swiper/css"
import "swiper/css/navigation"

export default function EmployersSlider() {
	return (
		<>
			<Swiper
				modules={[Navigation]}
				spaceBetween={32}
				slidesPerView={5.5}
				// navigation={true}
				loop
				navigation={{
					nextEl: ".image-swiper-button-next",
					prevEl: ".image-swiper-button-prev"
				}}
				breakpoints={{
					0: {
						slidesPerView: 1
					},
					767: {
						slidesPerView: 2.5
					},
					1200: {
						slidesPerView: 4.5
					}
				}}
			>
				{/* <SwiperSlide>
          <TestimonialCard
            imgSrc={james}
            name="James Holden"
            position="CEO of Starship Freight"
            text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
            hashtags="#TrustedPartner #EmployeeCare"
          />
        </SwiperSlide> */}

				<SwiperSlide>
					<TestimonialCard
						name="Malia R."
						text="I had the pleasure of working with Salusion as an insurance broker, as well a business client, and I couldn't be more impressed with their service. Their platform is incredibly user-friendly, making it easy for both employers and employees to navigate, which significantly streamlines the benefits management process. I've personally worked with Sean & Giles at the Salusion's team, who consistently went above and beyond to ensure our needs were met, providing personalized support that made a real difference. Their competitive pricing is another standout feature, offering exceptional value without compromising on quality. I highly recommend Salusion to any business looking to enhance their benefits administration, particularly those who value a seamless experience and top-notch customer service. Partnering with Salusion has been a game-changer for us, and I'm confident it would be for others too."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Dr. Megha S."
						text="I had the good fortune of being able to use Salusion and its products through my work place. Its products were very very easy to use. An issues or questions I had were resolved immediately with staff that were extremely knowledgable, helpful, friendly and through. I highly recommend Salusion. I specifically appreciated working with Giles Nugent. He is hands down the absoult best at his job! He immediate and professional attention, as well as kindness and pacience to make sure all issues are solved is above and beyond. He specifically made my experience A+. If I was to open my own business I would use Salusion. It is what any and every employee would want to use!"
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Alicia L."
						text="Our organization has had an outstanding experience with the Salusion application. The ease of use and the short turnaround time on reimbursements have been greatly appreciated. Our employees particularly appreciate how intuitive the interface is, making it simple to navigate and submit reimbursement requests. We highly recommend Salusion to any organization looking for a seamless and effective reimbursement solution."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Kyle C."
						text="It took 10 minutes to set up a QSEHRA for my small business. I really like that they manage the reimbursements for me."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="James Holden"
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
					/>
				</SwiperSlide>
			</Swiper>
		</>
	)
}
