import { Link } from "react-router-dom"
import "../styles.css"

export const question = "What are HRA plan documents?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Most HRAs are employee welfare benefit plans and are subject to ERISA (29 U.S.C. §§ 1001-1461)
						</p>

						<p className="text">
							QSEHRAs are unique in that they are excluded from the term “group health plan” (which is a type of
							employee welfare benefit plan) for purposes of the Code, ERISA, and the PHSA, freeing QSEHRAs from many
							compliance requirements and administrative burdens. QSEHRAs do not avoid all of the regulatory burdens
							applicable to more traditional HRAs, including ERISA’s general compliance requirements of a Plan Document
							and Summary Plan Description.
						</p>

						<p className="text">
							Section 402 of ERISA mandates that every employee welfare benefit plan be established and maintained
							according to a written Plan Document. Reflecting the HRA in a written plan document is important for a
							variety of reasons, including compliance with
						</p>

						<ol>
							<li>the statutory requirement that there be a written Plan Document;</li>
							<li>the requirements of ERISA and other federal mandates for specific plan provisions;</li>
							<li>reporting and disclosure requirements; and</li>
							<li>the fiduciary's duty to follow the Plan Document</li>
						</ol>

						<p className="text">
							Moreover, HRAs that are ERISA covered plans must provide a Summary Plan Description. The Summary Plan
							Description must be sufficiently comprehensive to apprise the plan’s participants and beneficiaries of
							their rights and obligations under the plan.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
