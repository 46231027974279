import { Link } from "react-router-dom"
import "../styles.css"

export const question = "What is the insurance requirement for a QSEHRA?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							QSEHRAs may only pay or reimburse medical expenses after the employee provides proof of minimum essential
							coverage (MEC), and each individual (including dependents) must have MEC the month in which an expense is
							incurred.
						</p>

						<p className="text">Examples of qualifying MEC:</p>

						<ul>
							<li>Plans purchased on a state or federal health insurance exchange</li>

							<li>
								Individual health plans bought outside a health insurance exchange that meet the standards for qualified
								health plans
							</li>

							<li>Employer-sponsored plans including COBRA and retiree plans</li>
							<li>Coverage under a parent's marketplace or job-based plan</li>
							<li>Most student health plans</li>
							<li>Medicare Part A</li>
							<li>Medicare Advantage plans, also known as Medicare Part C</li>
							<li>Most Medicaid coverage, except for limited coverage plans</li>
							<li>Children's Health Insurance Program (CHIP)</li>
							<li>Most TRICARE plans, also known as uniformed services health care programs</li>
							<li>Certain plans offered by the Department of Veterans Affairs (VA), including:</li>

							<ul>
								<li>VA health care program</li>
								<li>Civilian Health and Medical Program (CHAMPVA)</li>
								<li>Spina bifida health care program</li>
							</ul>

							<li>Health coverage for Peace Corps volunteers</li>
							<li>State high-risk pool plans that became effective on or before Dec. 31, 2014</li>
							<li>Department of Defense Nonappropriated Fund (DoD NAF) Health Benefits Program</li>
							<li>Refugee Medical Assistance (RMA) plans from the Administration for Children and Families</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
