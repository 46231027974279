import { Link } from "react-router-dom"
import "../styles.css"

export const question = "What is the definition of a part-time employee for an ICHRA and a QSEHRA?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Part-time employees are defined as employees who are not full-time under the Code §4980H regulations or
							who are part-time under the Code §105 regulations, as elected by the HRA sponsor.
						</p>

						<ul>
							<li>Using Code §4980H, a part-time employee works less than 30-hours a week on average.</li>

							<li>
								Using Code §105, employees whose customary weekly employment is less than 35 hours, if other employees
								in similar work with the same employer (or, if no employees of the employer are in similar work, in
								similar work in the same industry and location) have substantially more hours. Notwithstanding the
								preceding sentence, any employee whose customary weekly employment is less than 25 hours be considered
								part-time.
							</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
