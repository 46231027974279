import { Link } from "react-router-dom"
import "../styles.css"

export const question = "What expenses are eligible for reimbursement under an ICHRA?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							<b>Who</b>
							<br />A medical expense must be incurred by the employee, the employee's spouse, an employee's child who
							has not attained age 27 as of the end of the employee's taxable year, or the employee's tax dependent for
							health coverage purposes in order for its reimbursement to be excluded from the employee's income.
						</p>

						<p className="text">
							<b>What</b>
							<br />
							ICHRAs may reimburse only medical expenses defined in Code §213(d): Employers have discretion to choose
							which of the Code §213(d) expenses are reimbursable; employees should refer to their Summary Plan
							Description for limitations.
						</p>
						<ul>
							<li>
								The primary definition for “medical care” under Code §213(d), is amounts paid “for the diagnosis, cure,
								mitigation, treatment, or prevention of disease, or for the purpose of affecting any structure or
								function of the body.” In simpler terms, most out-of-pocket medical expenses are eligible for
								reimbursement, including transportation and lodging associated with the medical expenses.
							</li>

							<li>Procedures that are cosmetic or illegal are not eligible.</li>

							<li>
								The CARES Act, in 2020, further expanded medical care to include over-the-counter medicines (cold and
								flu, sunblock, etc...) and menstrual products.
							</li>

							<li>
								Code §213(d) includes as “medical care” insurance premiums. These include dental and vision premiums as
								well as the individual coverage health premiums.
							</li>

							<li>
								Code §213(d) includes as “medical care” certain qualified long-term care services. Qualified long-term
								care insurance premiums are generally deductible, but the deduction is limited. Only the premiums paid
								during a taxable year that do not exceed the indexed annual limit (i.e., “eligible long-term care
								premiums”) are deductible and eligible for reimbursement under an HRA.
							</li>
						</ul>

						<p className="text">
							<b>When</b>
							<br />
							ICHRAs can only reimburse expenses that are incurred during the plan year.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
