import { useState } from "react"
import { Link } from "react-router-dom"
import { toSalusionPrivate } from "../../util"

export default function ProductsHero({ title, video, buttonsHide, poster }) {
	const [showVideo, setShowVideo] = useState(() => false)

	function scrollToContact() {
		document.getElementById("start_hra").scrollIntoView({ behavior: "smooth" })
	}

	return (
		<section id="products_hero" className="first-padding first-section-full-background">
			<div className="container">
				<div className="salusion-guide-wrapper">
					<h1>{title}</h1>
					<p>Discover which HRA best fits your small business</p>

					{!buttonsHide && (
						<div className="salusion-guide-btn-wrapper">
							<Link onClick={(event) => toSalusionPrivate(event, "/create-account", false)}>
								Start Your Company's HRA
							</Link>

							<Link onClick={scrollToContact}>Speak with an Expert</Link>
						</div>
					)}

					<div className="salusion-product-video">
						{!showVideo && <img src={poster} alt={title} onClick={() => setShowVideo(true)} />}

						{showVideo && (
							<iframe
								src={`${video}&autoplay=1&mute=1`}
								title={title}
								allow="autoplay encrypted-media"
								allowFullScreen
								referrerPolicy="strict-origin-when-cross-origin"
								frameBorder="0"
							/>
						)}
					</div>
				</div>
			</div>
		</section>
	)
}
