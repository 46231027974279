import { Link } from "react-router-dom"
import { toSalusionPrivate } from "../../util"
import SalesSchedule from "./SalesSchedule"
import "./Hra.css"

export default function HraContact() {
	return (
		<section id="start_hra">
			<div className="container">
				<div className="hra-contact-wrapper white-wrapper-all" style={{ display: "flex", flexDirection: "column" }}>
					<h2>Speak with an Expert</h2>
					<SalesSchedule />

					<Link
						className="btn-linear"
						style={{ margin: "auto" }}
						onClick={(event) => toSalusionPrivate(event, "/create-account", false)}
					>
						Start Your Company's HRA Now
					</Link>
				</div>
			</div>
		</section>
	)
}
