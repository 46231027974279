import { Helmet } from "react-helmet-async"
import "../components/Rules/Rules.css"

import LargeBusinessAccordion from "../components/Large-Business/LargeBusinessAccordion"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"
import HraContact from "../components/Hra/HraContact"

import file from "../assets/documents/ICHRAs for Large Employers.pdf"
import ichraVsQsehra from "../assets/ichravsqsehra-poster.svg"
import ichraRules from "../assets/ichraRules.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"
import guideIchra from "../assets/guide-to-ichra.svg"

export default function LargeBusiness() {
	return (
		<>
			<Helmet>
				<title>Salusion - Large Business</title>
			</Helmet>

			<section id="large_business" className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>ICHRAs for Large Employers</h1>

					<div className="white-wrapper-all gsehra-rules-reg-wrapper">
						<h3>
							Employers with 50+ employees (ALEs) must offer affordable health coverage under the ACA. ICHRAs provide a
							flexible option to meet these requirements. Salusion simplifies affordability calculations by using safe
							harbors, ensuring compliance and cost-effectiveness.
						</h3>

						<a href={file} className="btn-linear" download="ICHRAs for Large Employers.pdf">
							Download PDF
						</a>

						<div className="guide-accordion-all">
							<LargeBusinessAccordion />
						</div>
					</div>
				</div>
			</section>

			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra"
				/>
				
				<ResourceCenterVideo
					resourceImg={ichraCalculator}
					title="Discover how much you can save with an ACA-compliant ICHRA"
					externalLink="product/aca/calculator"
				/>

				<ResourceCenterVideo
					resourceImg={ichraRules}
					title="Take a deep dive into the rules and regulations that govern ICHRAs"
					pageLink="/product/ichra/rules"
				/>

				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
			</ResourceCenter>
			<HraContact />
		</>
	)
}
