import { Link } from "react-router-dom"
import "../styles.css"

export const question = "What is the insurance requirement for an ICHRA?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Participation in an ICHRA is limited to employees and dependents who are enrolled in either individual,
							non-excepted benefit coverage (e.g., coverage consisting solely of vision or dental benefits will not
							qualify) purchased in the individual market that complies with health care reform's prohibition on
							lifetime and annual dollar limits (PHSA §2711) and its preventive services mandate (PHSA §2713) or
							Medicare (Parts A and B or Part C) for each month that they are covered by the ICHRA.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
