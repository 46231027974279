import { Link } from "react-router-dom"
import "../styles.css"

export const question = "What is individual coverage health insurance?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Individual health insurance coverage refers to a health insurance policy that you purchase on your own,
							rather than being provided through an employer or a government program. It covers medical expenses for a
							single person or family and can be obtained through the health insurance marketplace, directly from
							insurance companies, or through brokers.
						</p>

						<p className="text">
							This type of coverage typically includes benefits such as doctor visits, hospital stays, preventive care,
							prescription drugs, and more, depending on the plan you choose. Technically the insurance must be
							considered non-excepted benefit coverage that complies with health care reform's prohibition on lifetime
							and annual dollar limits (PHSA §2711) and its preventive services mandate (PHSA §2713).
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
