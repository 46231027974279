import Accordion from "react-bootstrap/Accordion"

export default function IchraAccordion() {
	return (
		<Accordion>
			<Accordion.Item eventKey="0">
				<Accordion.Header>Individuals Who Can Participate in an ICHRA</Accordion.Header>
				<Accordion.Body>
					<h4>Employees With Individual Coverage Insurance or Medicare</h4>
					<p>
						Employees are eligible to participate in an ICHRA if they are enrolled in one of the following health plans
						for each month that they are covered by the ICHRA.
					</p>
					<ul>
						<li>
							Health insurance purchased in the individual market that adheres to health care reform rules, specifically
							the prohibition on lifetime and annual dollar limits (PHSA §2711) and the preventive services mandate
							(PHSA §2713)
						</li>
						<li>Medicare (Parts A and B or Part C)</li>
					</ul>
					<h4>Owners Are Not Employees</h4>
					<p>The following individuals don't qualify as employees and cannot participate in an ICHRA:</p>
					<ul>
						<li>Sole proprietors</li>
						<li>Partners</li>
						<li>
							More-than-2% S corporation shareholders (more-than-2% shareholder's children, dependents, parents, and
							grandparents also cannot participate in the S corporation's ICHRA)
						</li>
						<li>Nonemployee independent contractors</li>
						<li>Nonemployee owners (employee owners of C corporations and LLCs can participate)</li>
					</ul>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="1">
				<Accordion.Header>ICHRA Classes</Accordion.Header>
				<Accordion.Body>
					<h4>ICHRAs Are Offered to a Class of Employees</h4>
					<p>
						ICHRAs are offered to a class of employees on the same terms and conditions to all employees within the
						class. For the purpose of an ICHRA, employees can be segmented into the following classes:
					</p>
					<ul>
						<li>Full-time employees</li>
						<li>Part-time employees</li>
						<li>Employees who are paid on a salaried basis</li>
						<li>Non-salaried employees (e.g., hourly employees)</li>
						<li>Employees whose primary site of employment is in the same rating area</li>
						<li>Seasonal employees</li>
						<li>
							Employees included in a unit covered by a particular collective bargaining agreement (or an appropriate
							related participation agreement) in which the ICHRA sponsor participates, as described under Code §105
						</li>
						<li>
							Employees who have not satisfied a waiting period for coverage, if the waiting period complies with health
							care reform's waiting period requirements
						</li>
						<li>Nonresident aliens with no U.S.-based income</li>
						<li>Employees hired for temporary placement at an unrelated entity</li>
						<li>
							Employees who are in a combination of two or more of the classes (e.g., full-time employees covered by a
							particular collective bargaining agreement).
						</li>
					</ul>
					<p>
						Participants may be treated as belonging to a class of employees based on whether they are, or are not,
						included in one of the above classes.
					</p>
					<h4>Classes Determined at Common Law Employer Level</h4>
					<p>
						Classes are determined at the common-law employer level, rather than on a controlled group basis (i.e.,
						without regard to the rules under Code §414(b), (c), (m), and (o) that would treat a common-law employer and
						other entities as a single employer). This allows different employers within a controlled group to vary
						benefits between affiliates with respect to classifications that could be considered a single class within a
						single employer.
					</p>
					<h4>Cannot Offer Group Health and an ICHRA to the Same Class of Employees</h4>
					<p>
						The ICHRA sponsor cannot offer a group health plan to the same class of employees that is offered an ICHRA.
						However, a special rule for new hires allows employers to offer a traditional plan to current employees
						within a class while offering an ICHRA to employees in the class who are hired on or after a certain future
						date.
					</p>
					<h4>Offering Group Health Invokes Minimum Class Sizes</h4>
					<p>
						If the ICHRA sponsor offers a group health plan to any class of employees and offers an ICHRA to one or more
						other classes of employees, then a minimum-class-size requirement to certain classes of employees that are
						offered an ICHRA. (The requirement does not apply to classes of employees that are offered a traditional
						health plan or are offered no coverage). The following classes are subject to the minimum-class-size
						requirement if they are offered an ICHRA:
					</p>
					<ul>
						<li>Full-time employees, if part-time employees are offered a traditional health plan</li>
						<li>Part-time employees, if full-time employees are offered a traditional health plan</li>
						<li>Salaried employees</li>
						<li>Non-salaried employees</li>
						<li>
							Employees whose primary site of employment is in the same rating area, unless the rating area is a state
							or a combination of two or more entire states
						</li>
						<li>
							A class of employees created by combining at least one of the preceding classes with any other class.
						</li>
					</ul>
					<p>
						However, the requirement does not apply if the class is a combination of one of the preceding classes and a
						class of employees who have not satisfied a waiting period for coverage. If the special rule for new hires
						is used, the minimum-class-size requirement will not apply to the new hire subclass unless the subclass is
						later subdivided, in which case the requirement will apply if it would otherwise be applicable.
					</p>
					<p>
						If a class of employees is subject to the minimum-class-size requirement, then the class must consist of a
						minimum number of employees in order for the ICHRA sponsor to treat that as a separate class of employees.
						The minimum number of employees that must be in a class of employees subject to the minimum-class-size
						requirement is as follows:
					</p>
					<ul>
						<li>10 employees, for an employer with fewer than 100 employees</li>
						<li>
							A number (rounded down to a whole number) equal to 10% of the total number of employees, for an employer
							with 100 to 200 employees
						</li>
						<li>20 employees, for an employer with more than 200 employees.</li>
					</ul>
					<p>
						The applicable number is determined prior to the beginning of each plan year for that year based on the
						number of employees that the employer reasonably expects to employ on the first day of the plan year.
						Whether a class has the minimum required number of employees is based on the number of employees in the
						class who are offered the ICHRA as of the first day of the plan year (not the number who actually enroll in
						the ICHRA). The determination is not affected by changes in the number of employees in the class during the
						plan year.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header>ICHRAs, ALEs & Employer Shared Responsibility</Accordion.Header>
				<Accordion.Body>
					<h4>Background Information</h4>
					<p>
						The Affordable Care Act ("ACA") includes provisions in the United States Internal Revenue Code related to
						employer-sponsored health insurance coverage. In particular, Code §4980H addresses the employer shared
						responsibility provisions. These provisions require Applicable Large Employers ("ALEs") to offer affordable
						health insurance coverage to their full-time employees and their dependents. Large employers that do not
						comply with these requirements may be subject to penalties.
					</p>
					<p>There are two subsections under Code §4980H:</p>
					<ul>
						<li>
							Code §4980H(a): This subsection imposes a penalty on ALEs who fail to offer minimum essential coverage to
							at least 95% of their full-time employees, and their dependents, and at least one full-time employee
							receives a premium tax credit for purchasing coverage through a Health Insurance Marketplace.
						</li>
						<li>
							Code §4980H(b): This subsection imposes a penalty on ALEs who offer coverage to their full-time employees,
							but the coverage is either unaffordable or does not provide minimum value, and at least one full-time
							employee receives a premium tax credit for purchasing coverage through a Health Insurance Marketplace.
						</li>
					</ul>
					<h4>Code §4980H(a)</h4>
					<p>
						By merely offering an ICHRA to an employee, an ALE is deemed to offer minimum essential coverage for the
						purposes of Code §4980H(a).
					</p>
					<h4>Affordability Under Code §4980H(b)</h4>
					<p>
						ICHRA coverage that is affordable is treated as providing minimum value for Code §4980H(b) purposes. An
						ICHRA is deemed affordable if the self-only amount made available to the employee under the ICHRA for a
						month is greater than the difference of a) the monthly self-only premium for the lowest-cost silver plan
						offered by the Exchange in the rating area where the employee resides over b) the product of the employee's
						household income and 8.39%.
					</p>
					<h4>Safe Harbors</h4>
					<p>
						To simplify affordability determinations for ALEs, the proposed IRS regulations include the following safe
						harbors:
					</p>
					<ul>
						<li>
							ALEs may determine the silver plan premium based on employees' primary site of employment, rather than
							their residence. For purposes of the location safe harbor, the primary site of employment is the location
							at which the ALE reasonably expects the employee to perform services on the first day of the plan year.
							However, the primary site of employment is treated as changing if the work location changes and the ALE
							expects the changes to be permanent or indefinite. In the event of such a change, the employee's primary
							site of employment generally is treated as changing no later than the first day of the second calendar
							month after the employee has begun performing services at the new location. If an employee regularly works
							from home or another location that is not on the ALE's premises but may be required to work at or report
							to a particular location, the latter location is the employee's primary site of employment. Otherwise, the
							remote worker's residence is the primary site of employment.
						</li>
						<li>
							ALEs may use the employee's W2, rate of pay, or federal poverty line to calculate their household income.
						</li>
						<li>
							ALEs may use the monthly premium for the applicable lowest-cost silver plan for January of the prior
							calendar year for a calendar year plan. In the case of a non-calendar-year plan, ALEs could use the
							monthly premium for the applicable lowest-cost silver plan for January of the current calendar year.
						</li>
					</ul>
					<p>
						Electing to use one or more safe harbors must be done on a uniform and consistent basis for all employees
						within a class.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="3">
				<Accordion.Header>Benefits Compatible with an ICHRA</Accordion.Header>
				<Accordion.Body>
					<h4>Employers Can Offer Excepted Benefits</h4>
					<p>
						An ICHRA sponsor may offer the same employees both an ICHRA and one or more excepted benefits (other than an
						excepted benefit HRA) such as vision or dental coverage or a health FSA.
					</p>
					<h4>Premium-Only ICHRAs Are HSA Compatible</h4>
					<p>
						ICHRAs can be designed to be HSA-compatible by only reimbursing premiums (including premiums for excepted
						benefit vision or dental coverage) or limiting reimbursements in accordance with the HSA rules. Employees in
						the same class can be offered a choice between an HSA-compatible, ICHRA and one that is not HSA-compatible
					</p>
					<h4>Off-Exchange Premiums Can Be Passed Through a Cafeteria Plan</h4>
					<p>
						Employers can allow employees to use pre-tax cafeteria plan salary reductions to pay the portion of the
						premiums for their individual medical coverage not covered by the ICHRA, so long as the coverage is
						purchased outside of an Exchange. If offered, salary reductions must be available on the same terms and
						conditions to all employees within a class.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="4">
				<Accordion.Header>Premium Tax Credits & Opting Out</Accordion.Header>
				<Accordion.Body>
					<h4>Premium Tax Credits</h4>
					<p>
						A premium tax credit (“PTC”) provides financial assistance to pay the premiums of a qualified health plan
						offered through a Marketplace by reducing the amount of tax you owe, giving you a refund, or increasing your
						refund amount. Typically PTCs are advanced and paid directly to the insurance provider based on the
						Marketplace’s estimate of the PTC that can be taken on your tax return. PTCs and the reconciliation of the
						advances are made on IRS Form 8962.
					</p>
					<h4>PTC Calculation</h4>
					<p>
						The IRS uses the second lowest cost Silver plan (“SLCSP”) on the exchange as the benchmark for the cost of
						employees insurance. In 2024, the PTC offered to employees is the difference between the cost of the SLCSP
						and 8.39% of an employee’s household income.
					</p>
					<h4>Affordability, Opting In/Out, and PTCs</h4>
					<p>
						If the self-only ICHRA allowance is greater than the difference between the cost of the SLCSP and 8.39% of
						the employee’s household income, then the ICHRA is considered “affordable”.
					</p>
					<ul>
						<li>If an employee is offered an affordable ICHRA, then they cannot claim PTCs.</li>
						<li>If an employee is offered an ICHRA and they opt into it, then they cannot claim PTCs.</li>
						<li>
							There is a separate affordability test for individuals related to the employee based on the cost of family
							coverage offered by the employee's employer. This test ensures that family members may be eligible for the
							Premium Tax Credit (PTC) even if the employee is not, allowing them to enroll in Exchange coverage with
							the PTC subsidy.
						</li>
						<li>
							If an employee opts out an ICHRA and the ICHRA is considered unaffordable, then they may claim the premium
							tax credit.
						</li>
					</ul>
					<h4>Employees Are Permitted to Opt Out</h4>
					<p>
						Employees who are eligible for coverage under an ICHRA must be permitted to opt out of and waive future
						reimbursements from the ICHRA (on behalf of themselves and all eligible dependents) with respect to each
						plan year.
					</p>
					<p>
						The HRA may establish timeframes for enrollment in (and opting out of) the HRA, but an opt-out opportunity
						must be provided in advance of the first day of the plan year. For participants or dependents who become
						eligible midyear, or participants who become eligible fewer than 90 days prior to the plan year, this
						opportunity must be provided during the applicable ICHRA enrollment period. Upon termination of employment,
						ICHRA participants must either forfeit the remaining balance (subject to COBRA) or be permitted to
						permanently opt out of and waive future reimbursements on behalf of themselves and all covered dependents
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="5">
				<Accordion.Header>Notice Requirements</Accordion.Header>
				<Accordion.Body>
					<h4>Notice Timing</h4>
					<p>
						A notice relating to the ICHRA must be provided at least 90 days before the beginning of each plan year or
						no later than the date an employee is first eligible to participate in the ICHRA, if the employee is not
						eligible to participate at the beginning of the plan year or when the notice is provided to other
						participants.
					</p>
					<p>
						The notice for the first plan year can be provided no later than the date on which the ICHRA is first
						effective for the participant.
					</p>
					<h4>Notice Content</h4>
					<p>
						Salusion uses the model notice recommended by the Departments of the Treasury, Labor, and Health and Human
						Services.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="6">
				<Accordion.Header>Reporting Requirements</Accordion.Header>
				<Accordion.Body>
					<h4>Small Employers: 1094-B & 1095-B</h4>
					<p>
						ICHRA providers not subject to the employer shared responsibility provisions file Forms 1094-B and 1095-B to
						report information about individuals covered by minimum essential health coverage.
					</p>
					<ul>
						<li>
							Form 1095-B is provided to individuals covered by minimum essential health coverage. Offering an ICHRA to
							an employee is considered offering minimum essential coverage.
						</li>
						<li>
							Form 1094-B serves as a transmittal form, summarizing and transmitting Forms 1095-B to the IRS. It
							includes details about the entity providing health coverage, such as the employer or insurance provider
						</li>
					</ul>
					<h4>ALEs: 1094-C & 1095-C</h4>
					<ul>
						<li>
							Employers with 50 or more full-time employees in the previous year use Forms 1094-C and 1095-C to report
							information about health coverage offers and enrollment for employees.
						</li>
						<li>
							Form 1094-C helps determine whether an ALE Member owes a payment under the employer shared responsibility
							provisions (section 4980H).
						</li>
						<li>Form 1095-C is also used to assess the eligibility of employees for the premium tax credit.</li>
					</ul>
					<h4>Filing Deadlines</h4>
					<p>
						Forms 1094 and 1095 must be filed with the IRS by February 28 (paper filing) or March 31 (electronic filing)
						of the year following the calendar year of coverage.
					</p>
					<h4>Special Enrollment Periods</h4>
					<p>
						Employers that offer a new ICHRA to employees prompt an individual market special enrollment period, which
						gives employees and their dependents 60 days to enroll in individual insurance coverage or change from one
						individual coverage plan to another.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="7">
				<Accordion.Header>Eligible Expenses</Accordion.Header>
				<Accordion.Body>
					<h4>What</h4>
					<p>ICHRAs may reimburse only medical expenses defined in Code §213(d):</p>
					<ul>
						<li>
							The primary definition for "medical care" under Code §213(d), is amounts paid "for the diagnosis, cure,
							mitigation, treatment, or prevention of disease, or for the purpose of affecting any structure or function
							of the body." In simpler terms, most out-of-pocket medical expenses are eligible for reimbursement,
							including transportation and lodging associated with the medical expenses.
						</li>
						<li>Code §213(d) also includes insurance premiums. These are reimbursable under an ICHRA.</li>
						<li>
							The CARES Act, in 2020, further expanded medical care to include over-the-counter medicines (cold and flu,
							sunblock, etc...) and menstrual products
						</li>
						<li>Procedures that are cosmetic or illegal are not eligible</li>
					</ul>
					<p>
						Employers choose which of the Code §213(d) expenses are reimbursable. Employers typically choose to cover
						all of Section 213(d) expenses (insurance premiums and out-of-pocket medical expenses) or insurance premiums
						only.
					</p>
					<h4>Who</h4>
					<p>
						The medical expense must be incurred by the employee, the employee's spouse, an employee's child who has not
						attained age 27 as of the end of the employee's taxable year, or the employee's tax dependent for health
						coverage purposes.
					</p>
					<h4>When</h4>
					<p>
						ICHRAs can only reimburse expenses that are incurred while the employee was an eligible employee during the
						plan year.
					</p>
					<h4>Substantiation</h4>
					<p>For a reimbursement to be tax-free, health claims must be substantiated with two items:</p>
					<ul>
						<li>
							Information from an independent third party describing the service or product, the date of the service or
							sale, and the amount of the expense
						</li>
						<li>
							A statement from the participant providing that the medical expense has not been reimbursed and that the
							participant will not seek reimbursement for the expense under any other health plan coverage
						</li>
					</ul>
					<p>Salusion captures and verifies employee health expenses.</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="8">
				<Accordion.Header>Mechanics of a Salusion ICHRA</Accordion.Header>
				<Accordion.Body>
					<h4>Plan Year</h4>
					<p>
						A plan year describes the 12-month period that an ICHRA is in effect. An ICHRA may be started in any month
						of the year. If an ICHRA is started on any date after January 1st, then the initial plan year is a "short
						plan year" ending on December 31st and subsequent plan years will be aligned with the calendar year.
					</p>
					<h4>Subsequent Plan Years</h4>
					<p>
						Salusion's ICHRA automatically rolls from one plan year to the next. 120 days before the start of the
						subsequent plan year, Salusion will make the subsequent plan year available to amend. 90 days before the
						start of the subsequent plan year, Salusion will send the required notices.
					</p>
					<h4>Annual Allowance</h4>
					<p>
						Employers define the annual allowance, which is the maximum amount that can be reimbursed under the ICHRA in
						a plan year. There is no cap on the annual allowance.
					</p>
					<h4>Annual Allowance by Family Size & Age</h4>
					<p>
						For each class of employees offered an ICHRA, the employer can increase the annual allowance available based
						on age (by up to three times the annual allowance available to the youngest participant) or family size, or
						both factors. The age and family size determinations are made as of the first day of the ICHRA plan year and
						will not change if the employee's circumstances change during the year (e.g., the employee marries or
						divorces).
					</p>
					<h4>Annual Allowance Availability</h4>
					<p>
						The entire annual allowance can be made available for reimbursement on the first day of the plan year.
						However, the vast majority of Salusion's clients make the allowance available pro rata on a monthly basis.
					</p>
					<p>
						For example, if the annual allowance is $3,600. An employer chooses to make the allowance available pro rata
						on a monthly basis. On the first day of the plan year, $300 is available for reimbursements.
					</p>
					<ul>
						<li>
							Suppose on the first day of the plan year, an employee submits an expense for $500. Salusion would process
							a $300 reimbursement immediately. The remaining $200 reimbursement would be processed on the first day of
							the following month when additional allowance is made available.
						</li>
						<li>
							Suppose the employee did not submit a claim in the first month of the plan year. On the first day of the
							following month $600 is available for reimbursement
						</li>
					</ul>
					<h4>Year End Runout</h4>
					<p>
						The year end runout is the period of time after a plan year ends that employees can submit expenses for that
						plan year. The year end runout on Salusion's ICHRAs is 75 days.
					</p>
					<h4>Termination Runout</h4>
					<p>
						The termination runout is the period of time after the employee is terminated that the employees can submit
						expenses for that plan year. The termination runout on Salusion's ICHRAs is 0 days.
					</p>
					<h4>Carryover Amounts</h4>
					<p>
						ICHRAs allow unused allowances to roll over from one plan year to the next. To avoid accumulating uncapped
						liabilities over time, Salusion's ICHRAs default setting is to disable carryover amounts. If you want to
						enable carryover amounts, please contact Salusion for assistance.
					</p>
					<h4>Reimbursements</h4>
					<p>
						Employers can utilize Salusion for reimbursement processing. Upon approval of a health expense, Salusion
						triggers an ACH transfer from the employer's operating account, transferring the funds directly to the
						employee's account. Reimbursements can occur within two business days of expense submission, or all approved
						expenses can be consolidated and reimbursed at the beginning of the following month.
					</p>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}
