import Accordion from "react-bootstrap/Accordion"

export default function QsehraRulesAccordion() {
	return (
		<Accordion>
			<Accordion.Item eventKey="0">
				<Accordion.Header>Employer Requirements to Sponsor a QSEHRA</Accordion.Header>
				<Accordion.Body>
					<p>An employer is eligible to adopt a QSEHRA if both of the following requirements are met:</p>
					<ul>
						<li>
							The employer is not an Applicable Large Employer (ALE) under Code §4980H, meaning they must have fewer
							than 50 full-time equivalent employees in the prior calendar year.
						</li>
						<li>
							The employer does not offer a group health plan to any of its employees (excepted benefits such as
							limited-scope dental and vision plans are counted as group health plans for this purpose).
						</li>
					</ul>
					<p>
						The IRS defines "group health plan" broadly, including major medical coverage, health FSAs, HRAs, and plans
						providing only excepted benefits (e.g., limited-scope dental, vision plans, and indemnity coverage).
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="1">
				<Accordion.Header>Individuals Who Can Participate in a QSEHRA</Accordion.Header>
				<Accordion.Body>
					<p>
						<b>Eligibility</b>
						<br />
						All employees are eligible to participate in a QSEHRA.
					</p>
					<p>
						<b>Owners Are Not Employees</b>
						<br />
						The following individuals do not qualify as employees and, therefore, cannot participate in a QSEHRA:
					</p>
					<ul>
						<li>Sole proprietors</li>
						<li>Partners</li>
						<li>
							More-than-2% S corporation shareholders (including their children, dependents, parents, and grandparents)
						</li>
						<li>Nonemployee independent contractors</li>
						<li>Nonemployee owners (C corporation and LLC employee owners can participate)</li>
					</ul>
					<p>
						<b>Excluded Groups</b>
						<br />
						Employers may exclude the following employees from QSEHRA participation:
					</p>
					<ul>
						<li>Employees who have not completed 90 days of service</li>
						<li>Employees under age 25 at the start of the plan year</li>
						<li>Part-time or seasonal employees</li>
						<li>
							Employees covered by a collective bargaining agreement (if health benefits were subject to good faith
							bargaining)
						</li>
						<li>Nonresident aliens with no U.S.-sourced earned income</li>
					</ul>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header>The Uniformity Requirement</Accordion.Header>
				<Accordion.Body>
					<p>
						A QSEHRA must reimburse eligible medical expenses "on the same terms to all eligible employees." This is
						known as the "uniformity requirement," with two key implications:
					</p>
					<ul>
						<li>A QSEHRA must be provided to all eligible employees unless they are in an excluded group.</li>
						<li>Employees cannot waive participation in the QSEHRA; it must be "provided," not merely "offered."</li>
					</ul>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="3">
				<Accordion.Header>The Insurance Requirement</Accordion.Header>
				<Accordion.Body>
					<p>
						QSEHRAs may only reimburse medical expenses after the employee provides proof of minimum essential coverage
						(MEC). IRS guidance states that each individual (including dependents) whose expenses will be reimbursed
						must have MEC for the month in which the expense is incurred.
					</p>
					<p>
						The initial proof of MEC must be provided at least annually for each individual whose expenses are to be
						reimbursed. For subsequent months, attestation that the individual continues to have MEC can be incorporated
						in the reimbursement claim form or process. By focusing on the time at which medical care is provided, the
						statute makes the exclusion hinge on when the expense is incurred, not when the reimbursement is paid.
					</p>
					<ul>
						<li>Proof of MEC must be provided at least annually for each individual whose expenses are reimbursed.</li>
						<li>
							Subsequent months may rely on an attestation that MEC continues, which can be incorporated into the
							reimbursement process.
						</li>
					</ul>
					<p>
						Failure to require proof of MEC before making reimbursements could disqualify the QSEHRA. Reimbursements
						made without MEC are considered taxable income. A QSEHRA cannot provide taxable reimbursements if proof of
						MEC is not provided.
					</p>
					<p>Salusion handles employee insurance verification.</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="4">
				<Accordion.Header>Eligible Expenses</Accordion.Header>
				<Accordion.Body>
					<p>
						<b>What</b>
						<br />
						QSEHRAs can reimburse medical expenses defined in Code §213(d), which includes:
					</p>
					<ul>
						<li>
							Expenses for the diagnosis, cure, treatment, or prevention of disease, as well as amounts paid to affect
							the structure or function of the body. Insurance premiums
						</li>
						<li>Insurance premiums</li>
						<li>Over-the-counter medicines and menstrual products (as expanded by the CARES Act in 2020)</li>
					</ul>
					<p>Procedures that are cosmetic or illegal are not eligible</p>
					<p>
						Employers can choose to cover all Code §213(d) expenses (insurance premiums and out-of-pocket medical
						expenses) or limit coverage to premiums only.
					</p>
					<p>
						<b>Important Consideration</b>
						<br />
						Reimbursements for premiums under a group health plan sponsored by the eligible employee's spouse or parent
						are taxable if the spouse's or parent’s share of the premiums was paid on a pre-tax basis.
					</p>
					<p>Including taxable expenses in a QSEHRA can result in one of the following:</p>
					<ul>
						<li>Reimbursements processed through payroll to account for tax withholding.</li>
						<li>
							Reimbursements processed by Salusion, with the employer making a one-time payroll adjustment to account
							for taxable income. For simplicity, Salusion offers options that exclude taxable expenses, making
							administration easier.
						</li>
					</ul>
					<p>
						<b>Who</b>
						<br />
						The medical expense must be incurred by the employee, their spouse, their child (under age 27 by the end of
						the tax year), or the employee’s tax-dependent for health coverage purposes.
					</p>
					<p>
						<b>When</b>
						<br />
						QSEHRAs can only reimburse expenses incurred while the employee is eligible during the plan year.
					</p>
					<p>
						<b>Substantiation</b>
						<br />
						For tax-free reimbursement, claims must be substantiated with:
					</p>
					<ul>
						<li>
							Documentation from an independent third party describing the service or product, the date of service or
							sale, and the amount.
						</li>
						<li>
							A statement from the participant confirming that the medical expense has not been reimbursed under any
							other health plan.
						</li>
					</ul>
					<p>Salusion captures and verifies employee health expenses.</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="5">
				<Accordion.Header>Reporting Requirements</Accordion.Header>
				<Accordion.Body>
					<p>
						<b>Form W-2 Reporting</b>
						<br />
						Employers must report the total amount of the employee's permitted QSEHRA benefit on Form W-2 in Box 12,
						using Code "FF." The reported amount is the total available reimbursement for the calendar year, not the
						actual amount received.
					</p>
					<p>
						<b>Patient-Centered Outcomes Research Trust Fund (PCOR) Fee</b>
						<br />
						Eligible employers sponsoring QSEHRAs must file Form 720 annually and pay the PCOR fee for plan years ending
						before October 1, 2029. Form 720 is due by July 31 of the year following the last day of the plan year.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="6">
				<Accordion.Header>Notice Requirements</Accordion.Header>
				<Accordion.Body>
					<p>
						Employers providing a QSEHRA must furnish a written notice to each eligible employee at least 90 days before
						the plan year begins. For employees becoming eligible midyear, the notice must be sent on or before the
						employee’s eligibility date.
					</p>
					<p>The notice must include:</p>
					<ul>
						<li>The amount of the permitted QSEHRA benefit and the date the QSEHRA is first provided.</li>
						<li>
							A statement that the employee must inform any Marketplace to which they apply for premium tax credits
							about the QSEHRA benefit and how it affects eligibility for credits.
						</li>
						<li>
							A statement explaining that reimbursements will be taxable if the employee does not have MEC and may
							result in liability for the individual shared responsibility payment.
						</li>
					</ul>
					<p>Failure to provide this notice does not jeopardize the QSEHRA’s status.</p>
					<p>Salusion sends employee notifications on behalf of employers.</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="7">
				<Accordion.Header>QSEHRA’s Effect on Premium Tax Credits</Accordion.Header>
				<Accordion.Body>
					<p>
						<b>Premium Tax Credits</b>
						<br />A premium tax credit (PTC) provides financial assistance to help pay premiums for a qualified health
						plan offered through a Marketplace. PTCs are typically advanced and paid directly to the insurance provider.
					</p>
					<p>
						<b>QSEHRAs Replace PTCs</b>
						<br />
						QSEHRAs reduce PTCs dollar-for-dollar. For example, if an employee receives $400 in PTCs and the employer
						offers $300 via a QSEHRA, the PTC is reduced to $100. Employees receiving an advance on the PTC should
						contact their insurance provider to adjust the size of the advance.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="8">
				<Accordion.Header>Special Enrollment Periods</Accordion.Header>
				<Accordion.Body>
					<p>
						Employers offering a new QSEHRA trigger an individual market special enrollment period, giving employees and
						their dependents 60 days to enroll in or change individual insurance coverage.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="9">
				<Accordion.Header>Mechanics of a Salusion QSEHRA</Accordion.Header>
				<Accordion.Body>
					<p>
						<b>Plan Year</b>
						<br />A plan year describes the 12-month period that a QSEHRA is in effect. A QSEHRA may be started in any
						month of the year. The employer has the option to make the initial plan year a "short plan year" and select
						a Plan End Date that is less than a year from the Plan Start Date.
					</p>
					<p>
						<b>Subsequent Plan Years</b>
						<br />
						Salusion’s QSEHRA automatically rolls from one plan year to the next. 120 days before the start of the
						subsequent plan year, Salusion will make the subsequent plan year available to amend. 90 days before the
						start of the subsequent plan year, Salusion will send the required notices.
					</p>
					<p>
						<b>Annual Allowance</b>
						<br />
						Employers define the annual allowance, which is the maximum amount that can be reimbursed under the QSEHRA
						in a plan year.
					</p>
					<p>
						<b>Annual Allowance by Family Size & Age</b>
						<br />
						Although a QSEHRA must provide for the reimbursement of eligible medical expenses “on the same terms to all
						eligible employees”, the employer can vary the annual allowance available under the arrangement based on the
						eligible employee's age and how many of an employee's family members are covered under the arrangement, or
						both factors. The age and family size determinations are made as of the first day of the QSEHRA plan year
						and will not change if the employee's circumstances change during the year (e.g., the employee marries or
						divorces).
					</p>
					<p>
						<b>Annual Allowance Limits</b>
						<br />
						In 2024, the annual QSEHRA limit is $6,150 for employees enrolled in self-only coverage and $12,450 for
						employees enrolled in family coverage.
					</p>
					<p>
						<b>Annual Allowance Availability</b>
						<br />
						Salusion’s makes the annual allowance available, pro rata, on a monthly basis.
					</p>
					<p>
						(eg. The annual allowance is $3,600. On the first day of the plan year, $300 is available for
						reimbursements.
					</p>
					<ul>
						<li>
							Suppose on the first day of the plan year, an employee submits an expense for $500. Salusion would process
							a $300 reimbursement immediately. The remaining $200 reimbursement would be processed on the first day of
							the following month when additional allowance is made available.
						</li>
						<li>
							Suppose the employee did not submit a claim in the first month of the plan year. On the first day of the
							following month $600 is available for reimbursement )
						</li>
					</ul>
					<p>
						<b>Year End Runout</b>
						<br />
						The year end runout is the period of time after a plan year ends that employees can submit expenses for that
						plan year. The year end runout on Salusion’s QSEHRA is 75 days.
					</p>
					<p>
						<b>Termination Runout</b>
						<br />
						The termination runout is the period of time after the employee is terminated that the employees can submit
						expenses for that plan year. The termination runout on Salusion’s QSEHRA is 0 days.
					</p>
					<p>
						<b>Carryover Amounts</b>
						<br />
						QSEHRAs allow unused allowances to roll over from one plan year to the next. Salusion's QSEHRAs disable
						carryover amounts.
					</p>
					<p>
						<b>Reimbursements</b>
						<br />
						Employers can utilize Salusion for reimbursement processing. Upon approval of a health expense, Salusion
						triggers an ACH transfer from the employer's operating account, transferring the funds directly to the
						employee's account. Reimbursements can occur approximately four business days after expense submission, or
						all approved expenses can be consolidated and reimbursed at the beginning of the following month.
					</p>
					<p>
						For those employers opting to handle reimbursements internally, a monthly report is provided, detailing the
						list of reimbursements to be processed.
					</p>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}
