import SalesSchedule from "../Hra/SalesSchedule"
import Input from "../Input"
import "./Contact.css"

export default function Contact() {
	return (
		<section id="contact" className="first-section first-padding">
			<div className="container">
				<div className="contact-wrapper white-wrapper-all">
					<h1>We Look Forward To Connecting With You</h1>
					<div className="contact-form-wrapper">
						<div className="row">
							<div className="col-12 col-md-7">
								<div className="schedule-left">
									<h2>Schedule a call</h2>
									<SalesSchedule />
								</div>
							</div>
							<div className="col-12 col-md-5">
								<div className="contact-form-right">
									<h2>Send a message</h2>
									<form action="" className="form-all contact-form">
										<Input name="name" placeholder="Name" type="text" />
										<Input name="email" placeholder="Email" type="email" />
										<div className="col-12">
											<div className="form-group">
												<textarea name="message" placeholder="Message" id="" className="form-control"></textarea>
											</div>
										</div>
										<button type="submit">Send Message</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
