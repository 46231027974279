import { Helmet } from "react-helmet-async"

import ProductsHero from "../components/Products/ProductsHero"
import HraContact from "../components/Hra/HraContact"

import guideGchra from "../assets/guide-gchra.svg"

export default function Gchra() {
	return (
		<>
			<Helmet>
				<title>Salusion - Gchra</title>
			</Helmet>
			<ProductsHero
				title="Simplified GCHRAs"
				poster={guideGchra}
				video="https://www.youtube.com/embed/i2ERXJJkxkw?si=zO81hNyHZGOkrluN"
			/>
			<div className="hra-padding-top">
				<HraContact />
			</div>
		</>
	)
}
