import { Link } from "react-router-dom"
import "../styles.css"

export const question = "Who can receive tax-free benefits under an HRA?"

export default function LearningEntry() {
	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							An HRA can provide tax-free benefits to employees as well as their spouses, children who have not attained
							age 27 by the end of the tax year, and tax dependents.{" "}
							<i>
								(Certain types of HRAs allow former employees and retired employees to participate. However, Salusion’s
								plan documents do not support their participation.)
							</i>
						</p>

						<p className="text">
							The following individuals do not qualify as employees and cannot participate in an HRA:
						</p>

						<ul>
							<li>sole proprietors</li>
							<li>partners</li>

							<li>
								more-than-2% S corporation shareholders{" "}
								<i>
									(more-than-2% shareholder's children, dependents, parents, and grandparents also cannot participate in
									the S corporation's HRA)
								</i>
							</li>

							<li>nonemployee independent contractors</li>

							<li>
								nonemployee owners <i>(employee owners of C corporations and LLCs can participate)</i>
							</li>
						</ul>

						<p className="text">
							Additionally, different HRAs have different insurance requirements to be eligible for tax-free benefits.
						</p>

						<ul>
							<li>
								ICHRA: participants must be enrolled in major medical insurance purchased in the individual market or
								Medicare
							</li>

							<li>QSEHRA: participants must be enrolled in a plan that provides minimum essential coverage</li>
							<li>GCHRA: participants must be enrolled in group major medical coverage</li>

							<li>
								EBHRA: participants must be offered the employer’s group major medical coverage{" "}
								<i>(enrollment is not required)</i>
							</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
