import { Helmet } from "react-helmet-async"

import ProductsHero from "../components/Products/ProductsHero"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import HraContact from "../components/Hra/HraContact"

import reimbursementOptions from "../assets/reimbursement-options.svg"
import ichraVsQsehra from "../assets/ichravsqsehra-poster.svg"
import ichraRules from "../assets/ichraRules.svg"
import employees from "../assets/50-employees.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"
import findBroker from "../assets/find-broker.svg"

export default function ReimbursementOptions() {
	return (
		<>
			<Helmet>
				<title>Salusion - Reimbursement Options</title>
			</Helmet>
			<ProductsHero
				title="Reimbursement Options"
				video="https://www.youtube.com/embed/rr3n_pvoCOI?si=uHs0hXI4W37s__Ff?autoplay=1"
				buttonsHide={true}
				poster={reimbursementOptions}
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					title="Discover which HRA best fits your small business"
					resourceImg={ichraVsQsehra}
					pageLink="/product/ichra-vs-qsehra"
				/>
				<ResourceCenterVideo
					title="Take a deep dive into the rules and regulations that govern ICHRAs"
					resourceImg={ichraRules}
					pageLink="/product/ichra/rules"
				/>
				<ResourceCenterVideo
					title="ICHRA topics for large employers"
					resourceImg={employees}
					pageLink="/product/ichra/large-business"
				/>
				<ResourceCenterVideo
					title="Discover how much you can save with an ACA-compliant ICHRA"
					resourceImg={ichraCalculator}
					externalLink="product/aca/calculator"
				/>
				{/* <ResourceCenterVideo
					title="Find a broker in your state that can help your employee find insurance"
					resourceImg={findBroker}
					pageLink="/product/ichra/find-a-broker"
				/>
				<ResourceCenterVideo
					title="Discussion of the pros and cons of each reimbursement option"
					resourceImg={reimbursementOptions}
					pageLink="/product/ichra/reimbursement-options"
				/> */}
			</ResourceCenter>
			<HraContact />
		</>
	)
}
