import "../components/Rules/Rules.css"

import QsehraRulesAccordion from "../components/Rules/QsehraAccordion"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import file from "../assets/documents/QSEHRA Rules & Regs.pdf"
import qsehraQuide from "../assets/guide-salusions.svg"
import ichraVsQsehra from "../assets/ichravsqsehra-poster.svg"

export default function QsehraRules() {
	return (
		<>
			<section id="gsehra_rules" className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>QSEHRA Rules & Regs</h1>

					<div className="white-wrapper-all gsehra-rules-reg-wrapper">
						<h3>
							A Qualified Small Employer Health Reimbursement Arrangement “QSEHRA” is an arrangement where the employer
							reimburses employees for medical care expenses up to an annual allowance for a plan year. The
							reimbursements are generally excludable from the employee's income and wages for federal income tax and
							employment tax purposes.
						</h3>

						<a href={file} className="btn-linear" download="QSEHRA Rules & Regs.pdf">
							Download PDF
						</a>

						<div className="guide-accordion-all">
							<QsehraRulesAccordion />
						</div>
					</div>
				</div>
			</section>

			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={qsehraQuide}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
			</ResourceCenter>
		</>
	)
}
