import { Helmet } from "react-helmet-async"
import ProductsHero from "../components/Products/ProductsHero"
import HraContact from "../components/Hra/HraContact"

import guideEbhra from "../assets/guide-ebhra.svg"

export default function Ebhra() {
	return (
		<>
			<Helmet>
				<title>Salusion - EBHRA</title>
			</Helmet>
			<ProductsHero
				title="Simplified EBHRAs"
				poster={guideEbhra}
				video="https://www.youtube.com/embed/i2ERXJJkxkw?si=zO81hNyHZGOkrluN"
			/>
			<div className="hra-padding-top">
				<HraContact />
			</div>
		</>
	)
}
