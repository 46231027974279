import { Helmet } from "react-helmet-async"

import "../components/Products/Products.css"
import ProductsHeroClick from "../components/Products/ProductsHeroClick"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"

import ichravsqshera from "../assets/ichravsqsehra-poster.svg"
import ichraRules from "../assets/ichraRules.svg"
import pdfFile from "../assets/dummy.pdf"
import employees from "../assets/50-employees.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"
import findBroker from "../assets/find-broker.svg"
import reimbursement from "../assets/reimbursement-options.svg"
import guideIchra from "../assets/guide-to-ichra.svg"

import { PRICING_DATA_ICHRA } from "../components/Products/data"
import { TABLE_DATA_ICHRA } from "../components/Products/data"

export default function Ichra() {
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
			</Helmet>
			<ProductsHeroClick
				title="Salusion Simplifies ICHRAs"
				video="https://www.youtube.com/embed/QI1M_6qYVao?si=uHs0hXI4W37s__Ff"
				poster={guideIchra}
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
				<ResourceCenterVideo
					resourceImg={ichraRules}
					title="Take a deep dive into the rules and regulations that govern ICHRAs"
					pageLink="/product/ichra/rules"
				/>
				<ResourceCenterVideo
					resourceImg={employees}
					title="ICHRA topics for large employers"
					pageLink="/product/ichra/large-business"
				/>
				<ResourceCenterVideo
					resourceImg={ichraCalculator}
					title="Discover how much you can save with an ACA-compliant ICHRA"
					externalLink="product/aca/calculator"
				/>
				{/* <ResourceCenterVideo
					resourceImg={findBroker}
					title="Find a broker in your state that can help your employee find insurance"
					pageLink="/product/ichra/find-brokers"
				/>
				<ResourceCenterVideo
					resourceImg={reimbursement}
					title="Discussion of the pros and cons of each reimbursement option"
					pageLink="/product/ichra/reimbursement-options"
				/> */}
			</ResourceCenter>
			<PriceComparison
				dataPricing={PRICING_DATA_ICHRA}
				dataCheckmark={TABLE_DATA_ICHRA}
				title="ICHRA Price Comparison"
			/>
			<HraContact />
		</>
	)
}
