import { useState, useEffect } from "react"

export default function SalesSchedule() {
	const [hideEventType, setHideEventType] = useState(() => "hide_event_type_details=0")
	const [hideLandingPage, setHideLandingPage] = useState(() => "hide_landing_page_details=0")

	const url = "https://calendly.com/salusion-inquiry/welcome-to-salusion"
	const embedDomain = "embed_domain=salusion.com"
	const embedType = "embed_type=Inline"
	const hideCookieBanner = "hide_gdpr_banner=1"

	function updateCalendlyDisplay() {
		if (window.innerWidth < 1200) {
			setHideEventType("hide_event_type_details=1")
			setHideLandingPage("hide_landing_page_details=1")
		} else {
			setHideEventType("hide_event_type_details=0")
			setHideLandingPage("hide_landing_page_details=0")
		}
	}

	useEffect(() => {
		updateCalendlyDisplay()
		window.addEventListener("resize", updateCalendlyDisplay)

		return () => window.removeEventListener("resize", updateCalendlyDisplay)
	}, [])

	return (
		<div className="calendly-wrapper">
			<iframe
				title="calendly"
				src={`${url}?${embedDomain}&${embedType}&${hideCookieBanner}&${hideEventType}&${hideLandingPage}`}
				width="100%"
			/>
		</div>
	)
}
