export default function Input({ name, label, type, placeholder }) {
	return (
		<div className="col-12">
			<div className="form-group">
				<label htmlFor={name}>{label}</label>
				<input required type={type} placeholder={placeholder} name={name} className="form-control" />
			</div>
		</div>
	)
}
